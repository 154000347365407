import { memo } from "react";
import Block from "../../../../components/block/block";
import { Button, Form } from "antd";
import { Icon } from "../../../../components/icon/icon";
import {
  FormInput,
  FormSelect,
  FormSwitch,
  FormTextarea,
} from "../../../../components/form/form";
import { SELECT_TYPE_OPTIONS } from "./constants";

const normalizeAnswers = (answers) => {
  if (!answers) return;

  if (answers[0]?.id) {
    return answers
      .map((a) =>
        a.type === "input"
          ? `<>${a.text}`
          : a.type === "none"
            ? `<none>${a.text}`
            : a.text,
      )
      .join("\n");
  }

  return answers;
};

export const QuestionList = memo(({ control, editMode }) => {
  if (!control.questions.value?.length) {
    return null;
  }

  return (
    <div className={"lf-task-question"} style={{ paddingTop: 16 }}>
      <h4 style={{ marginBottom: 16 }}>Completion Survey</h4>
      {control.questions.value.map((q, index) => {
        const itemKey = q.key || index;
        const isPhotoPicker = q.type === "photoPicker";

        return (
          <Block key={index} padding={16} style={{ marginBottom: 8 }}>
            <Block.Collapse
              title={(q.title === "" ? " " : q.title) || q.text}
              after={
                editMode ? (
                  <Button
                    type={"text"}
                    icon={<Icon name={"delete"} />}
                    onClick={() => control.questions.remove(index)}
                  />
                ) : null
              }
            >
              <div style={{ marginBottom: 24 }}></div>
              <Form.Item label={"Type"}>
                <FormSelect
                  options={SELECT_TYPE_OPTIONS}
                  placeholder="Select question type"
                  defaultValue={SELECT_TYPE_OPTIONS.find(
                    (option) => option.key === q.type,
                  )}
                  onSelect={(value, { key }) =>
                    control.questions.update(itemKey, "type")(key)
                  }
                  readonly={!editMode}
                />
              </Form.Item>
              <Form.Item label={"Question"}>
                <FormInput
                  placeholder="Title"
                  defaultValue={q.title || q.text}
                  onChange={control.questions.update(itemKey, "title")}
                  maxLength={1000}
                  readonly={!editMode}
                />
              </Form.Item>
              <Form.Item label={"Description"}>
                <FormTextarea
                  placeholder="Description"
                  defaultValue={q.description}
                  onChange={control.questions.update(itemKey, "description")}
                  maxLength={1000}
                  readonly={!editMode}
                />
              </Form.Item>
              {(q.type === "Multiple" || q.type === "Single") && (
                <Form.Item label={"Options"}>
                  <FormTextarea
                    placeholder={"Option 1\nOption 2\n<> Other"}
                    defaultValue={normalizeAnswers(q.answers)}
                    onChange={control.questions.update(itemKey, "answers")}
                    maxLength={1000}
                    autoSize={{ minRows: 3 }}
                    readonly={!editMode}
                  />
                </Form.Item>
              )}
              {isPhotoPicker && (
                <>
                  <Form.Item label={"Instructions title"}>
                    <FormInput
                      placeholder="Instructions title"
                      defaultValue={q.instructionsTitle}
                      onChange={control.questions.update(
                        itemKey,
                        "instructionsTitle",
                      )}
                      maxLength={500}
                      readonly={!editMode}
                    />
                  </Form.Item>
                  <Form.Item label={"Instructions"}>
                    <FormTextarea
                      placeholder="Instructions"
                      defaultValue={q.instructions}
                      onChange={control.questions.update(
                        itemKey,
                        "instructions",
                      )}
                      maxLength={1000}
                      autoSize={{ minRows: 3 }}
                      readonly={!editMode}
                    />
                  </Form.Item>
                </>
              )}
              {editMode && (
                <Form.Item label={"Required"}>
                  <FormSwitch
                    checked={q.required}
                    onChange={(value) =>
                      control.questions.update(itemKey, "required")(value)
                    }
                  />
                </Form.Item>
              )}
            </Block.Collapse>
          </Block>
        );
      })}
    </div>
  );
});
