import { useEffect, useState, useCallback, useMemo } from "react";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiSubjectsManagement from "../../../api/subjects-manager";
import ApiScenariosManagement from "../../../api/scenarios-management";
import { Body } from "../../../components/main-body/body";
import PageContentLoader from "../../../components/loader/page-content-loader";
import UploadCard from "../subject/uploads/upload-card";
import UploadModal from "../subject/uploads/upload-modal";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import debounce from "../../../common/utils/debounce";
import ApiSubjectsDashboard from "../../../api/subjects-dashboard";

const Uploads = () => {
  const [searchValue, setSearchValue] = useState("");
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();
  const [sourceList, setSourceList] = useState([]);
  const [cohortsList, setCohortsList] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);

  const getDashboardFilterItems = () => {
    ApiSubjectsDashboard.filterField().then((r) => {
      setCohortsList(r.data.cohorts?.map((c) => ({ id: c.id, name: c.value })));
    });
  };

  const fetchSubjectList = () => {
    ApiSubjectsManagement.getSubjectsSimple().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.id,
          name: s.displayId,
        }))
      );
    });
  };

  const fetchSourceList = () => {
    ApiScenariosManagement.getSources().then((r) => {
      setSourceList(
        r.data.sources.map((s) => ({
          id: s.id,
          name: s.title,
        }))
      );
    });
  };

  const fetchImages = async (currentPage = 1, query = searchValue, currentFilters = filters) => {
    try {
      const response = await ApiSubjectsManagement.getSubjectsUploads(id, {
        ...currentFilters,
        query,
        page: currentPage,
        createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
        createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
      });

      const newImages = response.data.uploads;

      setImages((prevImages) => {
        if (currentPage === 1) {
          return newImages;
        }
        const existingIds = new Set(prevImages.map((img) => img.id));
        const uniqueImages = newImages.filter((img) => !existingIds.has(img.id));
        return [...prevImages, ...uniqueImages];
      });
      setHasMore(newImages.length > 0);
      if (newImages.length > 0) {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error("Upload error:", error);
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (page > 1) {
      fetchImages(page);
    }
  }, [page]);

  const debouncedGetUploads = useCallback(debounce(fetchImages, 400), []);

  const handleUpdateSearchValue = useCallback((value) => {
    setSearchValue(value);
    setPage(1);
    setImages([]);
    debouncedGetUploads(1, value, filters);
  }, [filters, debouncedGetUploads]);

  const isFilterEmpty = (filter) => {
    return Object.keys(filter).length === 0 || 
      Object.values(filter).every(value => 
        (Array.isArray(value) && value.length === 0) || 
        (typeof value === "object" && value !== null && Object.keys(value).length === 0) || 
        value === undefined || value === null
      );
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    let equal_filters = null
    setFilters((prevFilters) => {
      let empty_filters = isFilterEmpty(prevFilters) && isFilterEmpty(newFilters)
      equal_filters = JSON.stringify(prevFilters) !== JSON.stringify(newFilters) && empty_filters
      if (JSON.stringify(prevFilters) === JSON.stringify(newFilters)) {
        return prevFilters;
      }
      return newFilters;
    });
    if (!equal_filters) {
      setPage(1);
      setImages([]);
      debouncedGetUploads(1, searchValue, newFilters);   
    }

  }, [searchValue, debouncedGetUploads]);

  useEffect(() => {
    console.log("useEffect fetchImages");
    fetchSubjectList();
    getDashboardFilterItems();
    fetchSourceList();
    fetchImages(1);
  }, []);

  const headerProps = useMemo(
    () => ({
      title: "Uploads",
      search: {
        value: searchValue,
        onHandleChange: handleUpdateSearchValue,
        placeholder: t("assets.btn.search"),
      },
    }),
    [searchValue, handleUpdateSearchValue]
  );

  return (
    <Body
      header={headerProps}
      scroll
      filters={
        <DropdownFilters
          filtersBy={[
            { name: "Cohorts", id: "cohortIds", children: cohortsList },
            { 
              name: "Subjects", 
              id: "subjectIds", 
              children: subjectsList 
            },
            { name: "Source", id: "sources", children: sourceList },
            { name: "Date period", id: "period", period: true },
          ]}
          updateFilterValue={handleUpdateFilters}
        />
      }
    >
      <InfiniteScroll
        dataLength={images.length}
        next={() => fetchImages(page)}
        hasMore={hasMore}
        loader={<PageContentLoader />}
      >
        <div className="upload-grid">
          {images.map((image) => (
            <UploadCard key={image.id} image={image} onClick={() => setSelectedImage(image)} />
          ))}
        </div>
      </InfiniteScroll>
      {selectedImage && (
        <UploadModal
          image={selectedImage}
          isOpen={Boolean(selectedImage)}
          close={() => setSelectedImage(null)}
        />
      )}
    </Body>
  );
};

export default Uploads;