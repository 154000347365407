import { Body } from "../../../components/main-body/body";
import { ArticleModal } from "./component/article-modal";
import "./article-management.css";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import { useAdminResource } from "../components/hooks/useAdminResource";
import ApiArticles from "../../../api/articles";
import AdminResourceTable from "../components/layouts/general/admin-resource-table";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ArticleManagement = ({mode}) => {
  const {
    form,
    list,
    active,
    update,
    authors,
    open,
    close,
    create,
    search,
    handleUpdateSearchValue,
    remove,
    tags,
    categories,
    updateFilterValue,
    updateHasImage,
    updateIsUpdatedImage,
    isUpdatedImage,
    isLoading,
  } = useAdminResource(ApiArticles, "pages", "articles.notification", "articles");

  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (id && list.length) {
      const currentItem = list.find((item) => item.id === id);
      if (currentItem) {
        open(currentItem, mode);
      }
    }
  }, [id, list]);

  useEffect(() => {
    if (mode === "create") {
      console.log('CREATE', mode)
      create()
    }

  }, [mode]);

  useEffect(() => {
    if (!id && active && mode !== "create") {
      console.log('CLOSE', mode)
      close();
    }
  }, [location.pathname, id, active, mode]);

  return (
    <Body
      header={{
        title: "Articles",
        create: { label: 'Create article', onClick: create },
        search: { value: search, onHandleChange: handleUpdateSearchValue },
      }}
      filters={
        <DropdownFilters
          filtersBy={[
            { name: "Tags", id: "tag", children: tags },
            { name: "Category", id: "categoryIds", children: categories },
            { name: "Author", id: "userIds", children: authors },
            { name: "Source", id: "external", children: [{ id: true, name: 'External' }, { id: false, name: 'Internal' }] },
          ]}
          updateFilterValue={updateFilterValue}
        />
      }
    >
      <ArticleModal
        articleId={active?.id}
        form={form}
        data={active}
        onClose={close}
        onSave={update}
        tags={tags}
        categories={categories}
        updateHasImage={updateHasImage}
        updateIsUpdatedImage={updateIsUpdatedImage}
        isLoading={isLoading}
        authors={authors}
        mode={mode}
      />
      <AdminResourceTable
        data={list}
        onHandleRowClick={open}
        remove={remove}
        tableComlumnKeys={["cover", "title", "updatedAt", "action"]}
        isLoading={isLoading}
        shouldDropCache={isUpdatedImage}
      />
    </Body>
  );
};
