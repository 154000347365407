// import { useEffect, useState, useCallback, useMemo } from "react";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAnalytics,
  fetchAnalytics,
  selecthasMoreAnalytics,
  selectAnalytics,
  selectAnalyticsLoading,
} from "../../../../store/slices/task-analytics";
import { TableCell } from "../../../../components/table/table-cell/table-cell";
import AuditAnalytcisHeader from "../audit-analytics-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
import moment from "moment";
import { Body } from "../../../../components/main-body/body";
import classNames from "classnames";
import "../../../../components/main-body/body.css";
import { Progress } from "antd";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import AnalyticsCard from "./analytics-card/analytics-card";
import { useNavigate, useLocation } from "react-router-dom";
import ApiSubjectsDashboard from "../../../../api/subjects-dashboard";
import ApiArticles from "../../../../api/articles";
import ApiPlanManagement from "../../../../api/plan-management";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";

const TasksAnalyticsTable = ({ navigation }) => {
  const defaultStartDate = moment().subtract(30, "days").utc().toISOString();
  const defaultEndDate = moment().utc().toISOString();
  const analytics = useSelector(selectAnalytics);
  const hasMore = useSelector(selecthasMoreAnalytics);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const isLoading = useSelector(selectAnalyticsLoading);
  const [filters, setFilters] = useState({
    period: [{ start: defaultStartDate, end: defaultEndDate }]
  });
  // const [scenariosList, setScenarioList] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [selectedAnalytics, setSelectedAnalytics] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [cohortsList, setCohortsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [plansList, setPlansList] = useState([]);

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const getDashboardFilterItems = () => {
    ApiSubjectsDashboard.filterField().then((r) => {
      setCohortsList(r.data.cohorts?.map((c) => ({ id: c.id, name: c.value })));
    });
  };

  const handleSelectAnalytics = (task) => {
    handleNavigate(`/reports/analytics/tasks/${task.id}`);
    setSelectedAnalytics(task);
    setOpen(true);
  };

  const closeTaskDrawer = () => {
    handleNavigate(`/reports/analytics/tasks`);
    setOpen(false);
    setSelectedAnalytics({});
  };

  const fetchData = (currentPage = page, query = searchValue, currentFilters = filters, reset = false) => {
    if (reset) {
      dispatch(clearAnalytics());
    }

    dispatch(
      fetchAnalytics({
        query,
        page: currentPage,
        ...currentFilters,
        createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
        createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
      })
    );
    setPage(currentPage + 1);
  };

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    setPage(1);
    fetchData(1, value, filters, true);
  };

  const fetchSubjectList = () => {
    ApiSubjectsManagement.getSubjectsSimple().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.id,
          name: s.displayId,
        }))
      );
    });
  };

  const fetchCategories = async () => {
    const response = await ApiArticles.getCategories();
    if (response.data && response.data.categories) {
      setCategoriesList(
        response.data.categories
      );
    }
  }

  const fetchPlans = async () => {
    const response = await ApiPlanManagement.getPlanList();
    if (response.data && response.data.plans) {
      setPlansList(
        response.data.plans.map((p) => ({
          id: p.id,
          name: p.title,
        }))
      );
    }
  }

  // const fetchScenarioList = () => {
  //   ApiScenariosManagement.getScenariosSimple().then((r) => {
  //     setScenarioList(
  //       r.data.scenarios.map((s) => ({
  //         id: s.id,
  //         name: s.title,
  //       }))
  //     );
  //   });
  // };

  // const fetchSubjectList = () => {
  //   ApiSubjectsManagement.getSubjectsSimple().then((r) => {
  //     setSubjectsList(
  //       r.data.subjects.map((s) => ({
  //         id: s.id,
  //         name: s.displayId,
  //       }))
  //     );
  //   });
  // };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const taskId = pathParts[pathParts.length - 1];

    if (taskId && taskId !== "tasks") {
      const foundTask = analytics.find((task) => task.id === taskId);
      if (foundTask) {
        setSelectedAnalytics(foundTask);
      } else {
        setSelectedAnalytics({ id: taskId });
      }
      setOpen(true);
    } else {
      setOpen(false);
      setSelectedAnalytics({});
    }
  }, [location.pathname, analytics]);

  useEffect(() => {
    fetchCategories();
    fetchPlans();
    fetchSubjectList();
    getDashboardFilterItems();
  }, []);

  useEffect(() => {
    dispatch(clearAnalytics());
    fetchData(1);
  }, [filters, dispatch]);

  const columns = [
    {
      title: t("analytics.table.task"),
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (_, analytics) => {
        const { avatar, avatarColor, backgroundColor, title } = analytics;
        return (
          <TableCell
            img={avatar ? encodeSvgWithColor(avatar, avatarColor) : " "}
            imgStyle={{
              backgroundColor: backgroundColor,
              backgroundSize: "50%",
            }}
            title={title}
            subtext={analytics?.Category?.name}
          />
        );
      },
      key: "title",
      width: "40%"
    },
    {
      title: t("analytics.table.last_completed"),
      sorter: (a, b) => moment(a.lastCompletedAt).valueOf() - moment(b.lastCompletedAt).valueOf(),
      dataIndex: "lastCompletedAt",
      render: (_, analytics) => (
        <span>
          {analytics.lastCompletedAt
            ? `${moment(analytics.lastCompletedAt)
                .utc()
                .format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A"}
        </span>
      ),
      key: "lastCompletedAt",
      width: "20%"
    },
    {
      title: t("analytics.table.plan"),
      dataIndex: "Plan",
      sorter: (a, b) => (a.plans || "").localeCompare(b.plans || ""),
      render: (_, analytics) => <span>{analytics.plans}</span>,
      key: "Plan",
      width: "15%"
    },
    {
      title: t("analytics.table.adherence"),
      dataIndex: "completionRate",
      sorter: (a, b) => (a.adherence || 0) - (b.adherence || 0),
      render: (_, analytics) => (
        <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Progress
            type="circle"
            percent={analytics.adherence || 0}
            width={25}
            strokeLinecap="butt"
            strokeWidth={25}
            strokeColor="#2D7FFA"
            trailColor="rgba(45, 127, 250, 0.2)"
            format={() => ""}
          />
          {analytics.adherence || 0}%
        </span>
      ),
      key: "completionRate",
      width: "15%"
    },
  ];

  const filterItems = [
    { name: "Category", id: "categoryIds", children: categoriesList },
    {
      name: "Date peroid",
      id: "period",
      period: true,
      defaultValue: [{ start: defaultStartDate, end: defaultEndDate }],
    },
    { name: "Plan", id: "planIds", children: plansList }
  ];

  const headerProps = useMemo(
    () => ({
      search: {
        value: searchValue,
        onChange: handleUpdateSearchValue,
        placeholder: t("assets.btn.search")
      },
    }),
    [searchValue]
  );

  return (
    <Body disabledPaddings={true}>
      <AuditAnalytcisHeader
        title={t("analytics.tasks")}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        header={headerProps}
        filters={filters}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={analytics}
          fetchData={() => fetchData(page, searchValue, filters)}
          hasMore={hasMore}
          tableColumns={columns}
          isLoading={isLoading}
          before={navigation}
          onHandleRowClick={handleSelectAnalytics}
        />
      </div>
      <AnalyticsCard
        analytics={selectedAnalytics}
        isOpen={isOpen}
        close={closeTaskDrawer}
        subjects={subjectsList}
        cohorts={cohortsList}
        initialPeriodFilter={filters.hasOwnProperty('period') ? { period: filters.period } : {} }
      />
    </Body>
  );
};

export default TasksAnalyticsTable;
