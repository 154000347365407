import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import Header from "../../../components/header/header";

const AuditEngagementHeader = ({
  t,
  filterItems,
  updateFilterValue,
  header,
  filters
}) => {
  return (
    <Header
      title={t("engagement.articles")}
      search={header.search}
      filters={
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
          filter={filters}
        />
      }
    />
  );
};

export default AuditEngagementHeader;
