import moment from "moment";
import scenarioIcon from "../../../../assets/scenario.svg";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tag } from "antd";
import { ReactComponent as SubjectIcon } from "../../../../assets/subject.svg";

const UploadCard = ({ image, onClick }) => {
  const isDailyTask = Boolean(image.dailyTask);
  const backgroundColor = isDailyTask ? image.dailyTask.backgroundColor : "#EFEFEF";
  const avatarUrl = isDailyTask
    ? encodeSvgWithColor(image.dailyTask.avatar, image.dailyTask.avatarColor)
    : scenarioIcon;
  const truncateText = (text, maxLength = 20) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  return (
    <div className="upload-card" onClick={onClick}>
      <LazyLoadImage
        src={image.signedUrl}
        alt={image.title}
        effect="blur"
        className="upload-image"
      />
      <div className="upload-info">
        <div
          className="upload-icon"
          style={{
            backgroundImage: `url(${avatarUrl})`,
            backgroundColor: backgroundColor,
          }}
        />
        <div className="upload-text">
          <p className="upload-caption">{image.source}</p>
          <p className="upload-created-at">
            {`${moment(image.createdAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`}
          </p>
        </div>
      </div>
      {image?.subject && (
        <div className="upload-tags">
          {image?.subject?.displayId && (
            <Tag className="upload-tag">
              <SubjectIcon className="upload-tag-icon" />
              {truncateText(image.subject.displayId)}
            </Tag>
          )}
          {image?.subject?.EnrollmentResponses?.[0]?.EnrollmentFieldOption?.value && (
            <Tag className="upload-tag">
              {truncateText(image.subject.EnrollmentResponses[0].EnrollmentFieldOption.value)}
            </Tag>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadCard;